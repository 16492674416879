<!--  -->
<template>
  <div style="height: 100%">
    <el-card class="box-card">
      <div class="search">
        <span>注册时间</span>
        <el-date-picker
          size="mini"
          style="margin-right: 60px"
          v-model="Timevalue"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
        <el-input
          size="mini"
          v-model="input"
          placeholder="请输入学生账号、昵称、手机号"
          style="width: 200px; margin-right: 30px"
        ></el-input>

        <el-button size="mini" style="" type="primary" @click="headOperation(0)"
          >搜索</el-button
        >
        <el-button type="primary" size="mini" @click="headOperation(1)"
          >重置</el-button
        >
      </div>

      <!-- //内容 -->
      <el-scrollbar style="height: 70%">
        <el-empty
          description="暂无数据"
          v-if="tableData.length == 0"
        ></el-empty>
        <el-table
          v-if="tableData.length > 0"
          :header-cell-style="{
            background: '#F3F7FF',
            color: '#0B1B31',
            fontSize: '14px',
            fontWeight: '500',
          }"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 15px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="用户id" width="120">
            <template slot-scope="scope">{{ scope.row.userId }}</template>
          </el-table-column>
          <el-table-column prop="fullName" label="用户名称" width="120">
          </el-table-column>
          <el-table-column
            prop="mobilePhone"
            label="手机号"
            show-overflow-tooltip
          >
          </el-table-column>
          <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
        </el-table>
      </el-scrollbar>
      <div style="margin-top: 20px">
        <el-button @click="toggleSelection([...tableData])">全选</el-button>
        <el-button @click="toggleSelection()">取消选择</el-button>
        <el-button type="primary" @click="OnDialogSet(2)">督促</el-button>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        :page-sizes="[10, 50, 100, 200]"
        @current-change="handleCurrentChange"
        :page-size="page.pageSize"
        :current-page.sync="page.pageNum"
        layout="sizes, prev, pager, next"
        :total="page.total"
        background
        style="margin-top: 20px; text-align: center"
      ></el-pagination>
    </el-card>

    <!-- 弹窗 -->
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.dialogVisible"
      width="800px"
    >
      <!-- <el-divider></el-divider> -->
      <div style="height: 430px" v-if="dialog.dialogVisible">
        <el-scrollbar style="height: 80%">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="收件人">
              <el-select
                v-model="value1"
                collapse-tags
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="item in tableData"
                  :key="item.userId"
                  :label="item.fullName"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标题" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="text">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="ruleForm.text"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>

      <div class="bottom">
        <el-button type="primary" :disabled="Isdisabled" @click="OnDialogSet"
          >发送</el-button
        >
        <el-button @click="OnDialogSet(1)">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createLogger } from "vuex";
import {
  classGetUserInfoPageByClass,
  progressReminderCreateProgressReminder,
} from "../../../api/api";
export default {
  data() {
    return {
      input: "", //自查询，姓名
      Timevalue: "",
      page: {
        pageSize: 10,
        total: 100,
        pageNum: 1,
      },
      tableData: [],
      ruleForm: {
        name: "",
        text: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        text: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      multipleSelection: [],
      value1: [],
      reply: "",
      dialog: {
        dialogVisible: false,
        title: "发送邮件",
      },
      Isdisabled: false,
    };
  },

  components: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var obj = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        query: {
          classId: this.$route.query.classId,
          startTime: this.currentTime.getTimestamp(this.Timevalue[0]),
          endTime: this.currentTime.getTimestamp(this.Timevalue[1]),
          search: this.input,
        },
      };
      classGetUserInfoPageByClass(obj).then((res) => {
        this.page.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    //发送督促
    OnDialogSet(type) {
      if (type == 1) {
        this.$refs["ruleForm"].resetFields();
        this.dialog.dialogVisible = false;
        return;
      }
      if (type == 2) {
        this.dialog.dialogVisible = true;
        var list = [];
        this.ruleForm.name = "";
        this.ruleForm.text = "";
        this.multipleSelection.map((i) => {
          list.push(i.userId);
        });
        this.value1 = list;
        return;
      }
      if (this.value1.length <= 0) {
        this.$message.error("请选择要发送的学生");
        return;
      }
      this.Isdisabled = true;

      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          //提交

          var obj = {};
          obj.title = this.ruleForm.name;
          obj.content = this.ruleForm.text;
          obj.userIdList = this.value1;
          obj.classId = this.$route.query.classId;
          progressReminderCreateProgressReminder(obj).then((res) => {
            if (res.message == "请求成功") {
              this.$message({
                message: "发送成功",
                type: "success",
              });
              this.dialog.dialogVisible = false;
              this.Isdisabled = false;
              this.init();
            } else {
              this.Isdisabled = false;
              this.$message.error(res.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //全选
    toggleSelection(rows) {
      this.$refs.multipleTable.clearSelection();
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    headOperation(val) {
      //0查询  1重置
      if (val == 1) {
        this.input = "";
        this.Timevalue = "";
      }
      this.page.pageNum = 1;
      this.init();
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.init();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.init();
    },
  },
};
</script>
<style lang='less' scoped>
@import "./index.less";
</style>
